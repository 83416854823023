import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Currency`}</h1>
    <h4>{`Oct 10–13, 2019 | Portland, ME`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAADVklEQVQozx3M/VOTBQDA8Qc6rcl53dUJYZJ2Z4F5dNnVD+UveO78wbtKz1LQriuLNIIrqIwuYAgCgbwZGi9rDNj7MzY2YHtgz8OzN7aBcyAvQYgOJP07vl398Pn1I+j7rmMZ0eF2duCf6ESevoMiewmqVoKqkVBwnFDQRVB1MSOPIPnseMYsiA49Vosem2UA0daFy2nA4zYh2Ew6xkbbCEgGZiNWUkkfK8sqq6sxVpan+Wt1nLWlKtZWnNxPdZCIWwjODCL5OpkY/x3fpJGAdIugMkxEHUDwiJ8gTdQSVj0k522sLqs82Ijz6OE86U2R7fRddtJmttL32ViTWVqMMj8nEVJFAtMOFHmUsDpCPCpyNz6KMDFWjyxdJxb+g8Wkno3VPtIPRB6nbTzZ7uDpkyF2Hpv4Z8fM1paNv9d7WLjXRSzaRFBpJaT0EAu1Mx8zkErcQJj0NCBLfcyGbaSSAZKpIRZX+llZH2B908Cj7Q42H3ax9bSTtc3bLCy1EInq8IzVE1LMhIMuZsNW5qLNJGM1CJNuLQFfDaEZG3Nz3UTnalHDOtRwDbOJn4nGfyS10Mi9hTYiCR1yqBqf1MDQYBXqdD2hGSNh1UJcLSYRKkbwuurweRqZDTbTd7uSm+1fMKXU4Rq/ij9QgRKuJpKoIRJrRFKqmZqpZEr5CbulEkVqQA3c+F9I/p6w/CuCW/wFt7OFoNxPa3MdB3JfoE9/BYtYhtNbjl8pxy9X4J6oxGy/SPl3J6iqOkVP9zUCUw4kn4Epv55paYiArxbBYT6L3XwGl9iIW6wk96VsviwtwmQvZchyFavzGz6/XMTFC+/xaUkhgiCwe3cmFd+eRfLfwj3ajNd9E6+7Hq9YhDCs12LsP4TJcBSr8Rh5r2RzoeQ4BuNldLqTFBw5iJCxi49OC1w6n8HevRoyn9lFWel+POLb2EfeQTRrcZiOYx1+F6G3+30Mva9i6C3AMfwmWm0e+W8cpvxKDiUfC+zR/JdkUXwug/KvBfa9qCF7n4Y73QcYtbyOfTgf+0ghlsFCrH+eRKgq+5DqHw5RV32U1oa30J54Gc2e5zitzeT8mWc5mJfF/pwszn2g4avPssjN0ZD/2vM01R+m87cC2puP0N5yjLamfBquneJfxKWIFPYzY6QAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Currency",
          "title": "Currency",
          "src": "/static/95705f6435ac7a0427d3a297b59afe24/c1b63/8.png",
          "srcSet": ["/static/95705f6435ac7a0427d3a297b59afe24/5a46d/8.png 300w", "/static/95705f6435ac7a0427d3a297b59afe24/0a47e/8.png 600w", "/static/95705f6435ac7a0427d3a297b59afe24/c1b63/8.png 1200w", "/static/95705f6435ac7a0427d3a297b59afe24/d61c2/8.png 1800w", "/static/95705f6435ac7a0427d3a297b59afe24/97a96/8.png 2400w", "/static/95705f6435ac7a0427d3a297b59afe24/eb645/8.png 2500w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Currency is often taken simply to mean money, but if currency also suggests relevance, presence, value and systems of exchange, what exactly has currency in an era in which value systems seem to be shifting? When a state of fluidity characterizes everything, how is value built and enhanced, and what kind of currency is worthy of our investment?`}</strong></p>
    <p><strong parentName="p">{`DesignInquiry invites makers and thinkers of all disciplines to contribute projects to its 2019 topic: Currency. Our source is the wellspring of value and communication systems that counter — or supplement — the precarious conditions of the present. The emergence of “alternative currencies,” from handshakes to skills-shares, and of systemic proposals ranging from postal banking to universal basic income schemes to crypto-currencies may represent efforts to stabilize exchange amid the collapse of mercantile systems, (see the recent e-flux journal “What’s Love Got to do with it?”) Think memory banks (Keith Hart), precarity pilots (Brave New Alps), network power (David Singh Grewal) and Errol Flynn swashbuckling backwards up the stairs.`}</strong></p>
    <p><strong parentName="p">{`This topic is a kind of creative stock-piling, celebrating creative practices of generosity and care to create a fertile laboratory of ideas, a skunkworks of invention to slowly release into the future. If money shares an etymological link with memory, muse and museum (a place for depositing memories) and currency shares a link with current, then what will be memorable and current tomorrow? Trophies on the wall or things in circulation? Solo achievement or collective inquiry? The love you make or the love you take?`}</strong></p>
    <p><strong parentName="p">{`Inside the Institute of Contemporary Art at Maine College of Art, we will have a working space with a risograph printer, a giant cinder block letterpress, projector, wallspace, bread-making cart, crockpot array, and spotty wifi.`}</strong></p>
    <p><strong parentName="p">{`Given the short amount of time on hand, the desire to make an artifact of our work, and the need to be timely and urgent in relation to our topic, we are interested in proposals leaning towards making. How might we respond to systems and hierarchies of value? To social constructs determining material realities? What are the invisible currencies we live by and how might we shed light? How do we transfer value? What do we treasure? What is worthless?`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      